import { addDays, addWeeks, addMonths, addYears } from 'date-fns';

export function dateMath(dateString, date = new Date()) {
  if (!dateString) {
    return null;
  }
  const regexp = new RegExp(/\+(\d+)([dwmy])$/);
  const match = dateString.match(regexp);

  if (!match) {
    return null;
  }

  const [, count, type] = match;

  switch (type) {
    case 'd':
      return addDays(date, count);
    case 'w':
      return addWeeks(date, count);
    case 'm':
      return addMonths(date, count);
    case 'y':
      return addYears(date, count);
  }
}
